import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Fade, LinearProgress, Typography, Stack } from '@mui/material';

import { Environment } from '@playq/octopus-common';

import { handleEnterPress, pluralize, throwGenericFailure } from '/helpers';
import { services2 } from '/api';

import { ISpaceDeletionProps } from './types';
import { AppPromotions } from './AppPromotions';
import { CircularProgressStyled, DialogContentStyled, ErrorIconStyled, FinishIcon } from './styles';

const mapIdsToObject = (ids: { id: { serialize: () => string } }[], status: string): Record<string, string> => {
  return ids.reduce<Record<string, string>>((acc, item) => {
    acc[item.id.serialize()] = status;
    return acc;
  }, {});
};

export const SpaceDeletion: FC<ISpaceDeletionProps> = memo(({ spaceID, spacePromotions, onDelete }) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const [open, setOpen] = useState(!!spacePromotions);
  const [pending, setPending] = useState(false);
  const [showLinearProgress, setShowLinearProgress] = useState(false);
  const [done, setDone] = useState(false);
  const [demotedPromotions, setDemotedPromotions] = useState<Record<string, string>>({});

  useEffect(() => {
    if (spacePromotions.byApp.length) {
      setOpen(true);
    }
  }, [spacePromotions]);

  const promotionsCount = useMemo(() => {
    return spacePromotions.byApp.reduce((acc, appPromotion) => {
      return (
        acc +
        appPromotion.flows.length +
        appPromotion.experiments.length +
        appPromotion.events.length +
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        +!!appPromotion.config +
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        +!!appPromotion.segment
      );
    }, 0);
  }, [spacePromotions]);

  const demotedPromotionsCount = useMemo(
    () => Object.values(demotedPromotions).filter((v) => !!v).length,
    [demotedPromotions]
  );

  const demotedPromotionsCountPercent = useMemo(
    () => (demotedPromotionsCount / promotionsCount) * 100,
    [demotedPromotionsCount, promotionsCount]
  );

  const faultyPromotionsCount = useMemo(
    () => Object.values(demotedPromotions).filter((status) => !!status && status !== 'finished').length,
    [demotedPromotions]
  );

  const handleDemoteAll = () => {
    setPending(true);
    setShowLinearProgress(true);

    const promises: Promise<void>[] = [];

    let badRequest = false;

    for (const appPromotions of spacePromotions.byApp) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (appPromotions.config) {
        promises.push(
          services2.appConfigService
            .demote(appPromotions.app, appPromotions.config, Environment.Sandbox, spaceID)
            .then((data) =>
              data.bifold(
                () =>
                  setDemotedPromotions((prevState) => ({
                    ...prevState,
                    [`${appPromotions.app.serialize()}#config`]: 'finished',
                  })),
                throwGenericFailure
              )
            )
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((e: Error) => {
              badRequest = true;
              setDemotedPromotions((prevState) => ({
                ...prevState,
                [`${appPromotions.app.serialize()}#config`]: e.message,
              }));
            })
        );
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (appPromotions.segment) {
        promises.push(
          services2.appSegmentsService
            .demote(appPromotions.app, appPromotions.segment, Environment.Sandbox, spaceID)
            .then((data) =>
              data.bifold(
                () =>
                  setDemotedPromotions((prevState) => ({
                    ...prevState,
                    [`${appPromotions.app.serialize()}#segment`]: 'finished',
                  })),
                throwGenericFailure
              )
            )
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((e: Error) => {
              badRequest = true;
              setDemotedPromotions((prevState) => ({
                ...prevState,
                [`${appPromotions.app.serialize()}#segment`]: e.message,
              }));
            })
        );
      }

      if (appPromotions.events.length) {
        promises.push(
          services2.appGameEventsService
            .demoteBatch(appPromotions.events, Environment.Sandbox, spaceID)
            .then((data) =>
              data.bifold(
                () =>
                  setDemotedPromotions((prevState) => ({
                    ...prevState,
                    ...mapIdsToObject(appPromotions.events, 'finished'),
                  })),
                throwGenericFailure
              )
            )
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((e: Error) => {
              badRequest = true;
              setDemotedPromotions((prevState) => ({
                ...prevState,
                ...mapIdsToObject(appPromotions.events, e.message),
              }));
            })
        );
      }
      if (appPromotions.experiments.length) {
        promises.push(
          services2.appExperimentsService
            .demoteBatch(appPromotions.experiments, Environment.Sandbox, spaceID)
            .then((data) =>
              data.bifold(
                () =>
                  setDemotedPromotions((prevState) => ({
                    ...prevState,
                    ...mapIdsToObject(appPromotions.experiments, 'finished'),
                  })),
                throwGenericFailure
              )
            )
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((e: Error) => {
              badRequest = true;
              setDemotedPromotions((prevState) => ({
                ...prevState,
                ...mapIdsToObject(appPromotions.experiments, e.message),
              }));
            })
        );
      }
      if (appPromotions.flows.length) {
        promises.push(
          services2.flowsService
            .demoteBatch(appPromotions.flows, Environment.Sandbox, spaceID)
            .then((data) =>
              data.bifold(
                () =>
                  setDemotedPromotions((prevState) => ({
                    ...prevState,
                    ...mapIdsToObject(appPromotions.flows, 'finished'),
                  })),
                throwGenericFailure
              )
            )
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((e: Error) => {
              badRequest = true;
              setDemotedPromotions((prevState) => ({
                ...prevState,
                ...mapIdsToObject(appPromotions.flows, e.message),
              }));
            })
        );
      }
    }

    Promise.all(promises).then(() => {
      setPending(false);
      setDone(true);
      if (!badRequest) {
        setTimeout(() => {
          onDelete(spaceID);
        }, 500);
      }
    });
  };

  const handleClose = () => {
    if (!pending) {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} onKeyDown={handleEnterPress(handleDemoteAll)}>
      <DialogTitle>DELETE</DialogTitle>
      <DialogContentStyled>
        <Stack spacing={1}>
          Found {pluralize(promotionsCount, 'promotion')}:
          {spacePromotions.byApp.map((appPromotions) => (
            <AppPromotions
              key={appPromotions.app.serialize()}
              promotions={appPromotions}
              demotedPromotions={demotedPromotions}
            />
          ))}
          <Fade in={showLinearProgress}>
            <div>
              <Typography variant='body2' color='textSecondary'>
                {done && faultyPromotionsCount ? (
                  <>
                    <ErrorIconStyled />
                    Can&apos;t demote {pluralize(faultyPromotionsCount, 'promotion')}
                  </>
                ) : (
                  <>
                    {done ? <FinishIcon /> : <CircularProgressStyled size={14} />}
                    {done ? 'Done' : 'Processing...'} {demotedPromotionsCount}/{promotionsCount}
                  </>
                )}
              </Typography>
              <LinearProgress variant='determinate' value={demotedPromotionsCountPercent} />
            </div>
          </Fade>
        </Stack>
      </DialogContentStyled>
      <DialogActions>
        <Button onClick={handleClose} disabled={pending} color='primary'>
          CANCEL
        </Button>
        <Button onClick={handleDemoteAll} disabled={pending} color='primary'>
          DEMOTE ALL AND DELETE SPACE
        </Button>
      </DialogActions>
    </Dialog>
  );
});
