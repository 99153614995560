import { QueryClient, QueryClientProvider, QueryKey } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isPlainObject } from 'lodash';

import { FCWithChildren } from './models';

const config = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // Eslint can show that function 'queryKeyHashFn' unused
      // But this method replacing basic used 'hashQueryKey' method
      // You can delete it, only if understanding how it can affect on reactQuery performance
      queryKeyHashFn: (queryKey: QueryKey): string => {
        return JSON.stringify(queryKey, (_, val): unknown => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (val?.serialize !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
            return val.serialize();
          } else {
            // If key isn't ID class we will use  the default method of creating hash https://github.com/TanStack/query/blob/f9ebf9aaae59050331c22f143d835e46fac402cc/packages/query-core/src/utils.ts#L269-L280
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return isPlainObject(val)
              ? Object.keys(val)
                  .sort()
                  .reduce<{ [key: string]: string | unknown }>((result, key) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    result[key] = val[key];
                    return result;
                  }, {})
              : val;
          }
        });
      },
    },
  },
};

const queryClient = new QueryClient(config);

export const ReactQueryClientProvider: FCWithChildren = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools position={'bottom-right'} />
  </QueryClientProvider>
);
