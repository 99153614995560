import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { SpaceID } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';
import { SpacesSort, QuerySpacesResponse } from '@playq/octopus2-apps';

import { usePersistedQuery } from '/hooks/usePersistedQuery';
import { spacesServiceQueryKeys, useSpaceDelete, useSpacesQuery } from '/api/hooks/spacesService';
import { experimentsQueryKeys } from '/api/hooks/experimentsService';
import { flowQueryKeys } from '/api/hooks/flows';
import { gameEventsQueryKeys } from '/api/hooks/gameEventsService';
import { queryBulkPromotionsKeys } from '/api/hooks/promotions';
import { authToolkit, activeSpaceToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { PersistedQueryKeys } from '/common/models';

import { ISpacesQuery } from './types';

export const useSpacesList = (
  initialQuery?: ISpacesQuery,
  options?: UseQueryOptions<QuerySpacesResponse | undefined, GenericFailure | Error>
) => {
  const firstRender = useRef(false);
  const [query, setQuery] = usePersistedQuery(PersistedQueryKeys.SpacesList, SpacesSort, initialQuery);
  const [entitiesProcessing, setEntitiesProcessing] = useState<{ [id: string]: boolean }>({});
  const isAdmin = useSelector(authToolkit.selectors.isAdmin);
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const setEntityProcessing = useCallback(
    (id: string, isProcessing: boolean) => setEntitiesProcessing((prevState) => ({ ...prevState, [id]: isProcessing })),
    []
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (initialQuery) {
      setQuery(initialQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuery, initialQuery?.filterBy, initialQuery?.iterator, initialQuery?.sortBy]);

  const {
    spaces,
    total,
    isLoading,
    refetch,
    tags: spacesQueryKey,
    mutateDeletedSpace,
  } = useSpacesQuery(query.iterator, query.sortBy, query.filterBy, options);

  const { mutate: deleteSpace } = useSpaceDelete({
    onMutate: (spaceID: SpaceID) => {
      setEntityProcessing(spaceID.id, true);
    },
    onSuccess: (_res, spaceID: SpaceID) => {
      mutateDeletedSpace(spaceID);
      queryClient.removeQueries(queryBulkPromotionsKeys);
      queryClient.removeQueries(experimentsQueryKeys);
      queryClient.removeQueries(flowQueryKeys);
      queryClient.removeQueries(gameEventsQueryKeys);

      if (activeSpace?.id.serialize() === spaceID.serialize()) {
        dispatch(activeSpaceToolkit.actions.reset());
      }

      snackbarService.success(`Space ${spaceID.id} has been deleted`);
    },
    onError: () => {
      refetch();
    },
    onSettled: (_res, _err, id) => {
      setEntityProcessing(id.id, false);
    },
    removeQueriesKeys: spacesServiceQueryKeys,
    excludedRemoveQueriesKeys: spacesQueryKey,
  });

  return {
    spaces,
    spacesQueryKey,
    total,
    query,
    pending: isLoading,
    activeSpace,
    entitiesProcessing,
    canWrite: isAdmin,

    setQuery,
    deleteSpace,
    refresh: refetch,
  };
};
