import { uuidValidationSchema } from '/helpers';

import { CONFIGURATION } from './constants';

export const sandboxPathName = 'sandbox';
export const livePathName = 'live';

export const capitalizeFirstLetter = (word: string): string => {
  const trimmedWord = word.trim();
  return trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1);
};

export const isUUID = (path: string): boolean => uuidValidationSchema.isValidSync(path);

export const isId = (path: string): boolean => {
  const isSerialId = isNaN(+path);

  return isUUID(path) || !isSerialId;
};

export const isENV = (parsedPath: string): boolean => {
  const path = parsedPath.toLowerCase();
  return path === sandboxPathName || path === livePathName;
};

export const parsePath = (
  path: string,
  entityName: string | undefined,
  entityIdSetter: (entity: string | undefined) => void,
  moduleSetter: (entity: string | undefined) => void,
  setEnv: (entity: string | undefined) => void
): string => {
  const splittedPath = path.split('/');
  const isConfiguration = splittedPath.some((part) => part.toLowerCase() === CONFIGURATION);
  if (isConfiguration) {
    return 'Configuration';
  }
  const lastItem = splittedPath[splittedPath.length - 1] || '';
  const penultimateItem = splittedPath[splittedPath.length - 2] || '';
  const prePenultimateItem = splittedPath[splittedPath.length - 3] || '';
  const fourthItemfromEnd = splittedPath[splittedPath.length - 4] || '';

  if (isId(lastItem)) {
    entityIdSetter(lastItem);

    let moduleName: string;
    if (isId(penultimateItem) || isENV(penultimateItem)) {
      if (isId(prePenultimateItem) || isENV(prePenultimateItem)) {
        entityIdSetter(penultimateItem);
        moduleName = fourthItemfromEnd;
      } else {
        moduleName = prePenultimateItem;
      }
    } else {
      moduleName = penultimateItem;
    }

    moduleSetter(moduleName);
    splittedPath.forEach((item) => (isENV(item) ? setEnv(item) : false));

    if (entityName !== undefined) {
      return `${entityName} • ${capitalizeFirstLetter(moduleName)}`;
    } else if (isId(penultimateItem) || isENV(penultimateItem)) {
      return isENV(prePenultimateItem) ? fourthItemfromEnd : prePenultimateItem;
    } else {
      return penultimateItem;
    }
  }

  entityIdSetter(undefined);

  const isAuthPage = splittedPath.find((i) => i === 'auth');
  if (isAuthPage) {
    return 'Google Auth';
  }

  return lastItem;
};
