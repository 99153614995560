import { FC, memo } from 'react';

import { ProgressIndicatorStyled } from './styles';
import { IProgressIndicatorProps } from './types';

export const ProgressIndicator: FC<IProgressIndicatorProps> = memo((props) => {
  const { isVisible }: IProgressIndicatorProps = props;

  return isVisible ? <ProgressIndicatorStyled color='secondary' /> : null;
});
