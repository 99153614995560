import { SnackbarContentCallback, VariantType } from 'notistack';
import { Dispatch, SetStateAction } from 'react';

import { OneOf } from '/helpers/oneOfType';

export const CUSTOM_SNACKBAR_KEY = 'content';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IDefaultSnackbarProps<A = any> = {
  msg: string;
  type: VariantType;
  action?: A;
  persist?: boolean;
};

export type ICustomSnackbarProps = {
  [CUSTOM_SNACKBAR_KEY]: SnackbarContentCallback;
};

export type ISnackbarProps = OneOf<[IDefaultSnackbarProps, ICustomSnackbarProps]>;

export interface ISnackbarCustomContext {
  setAreDuplicationsPrevented: Dispatch<SetStateAction<boolean>>;
}
