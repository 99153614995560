import { useEffect } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import { CUSTOM_SNACKBAR, snackbarService } from '/common/snackbarService';
import { FCWithChildren } from '/common/models';

import { CUSTOM_SNACKBAR_KEY, ISnackbarProps } from './types';
import { SNACKBAR_AUTO_HIDE_DURATION_MS } from './constants';

const SnackbarContainer: FCWithChildren<WithSnackbarProps> = (provider) => {
  useEffect(() => {
    const sub = snackbarService.snackbars$.subscribe((props: ISnackbarProps) => {
      // 1.1 If content exists in props - that's declared snackbar with custom content
      if (CUSTOM_SNACKBAR_KEY in props) {
        provider.enqueueSnackbar(CUSTOM_SNACKBAR, {
          variant: 'default',
          autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION_MS,
          content: props[CUSTOM_SNACKBAR_KEY],
        });
        // 1.2 In all other cases - basic behavior, with prepared snackbar content
      } else {
        provider.enqueueSnackbar(props.msg, {
          variant: props.type,
          autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION_MS,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          action: props.action,
          persist: props.persist,
        });
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{provider.children}</>;
};

export default withSnackbar(SnackbarContainer);
