import { FC } from 'react';
import { Fade, Tooltip, Typography } from '@mui/material';

import { useAppQuery } from '/api/hooks/appsService';

import { IAppPromotionsProps } from './types';
import { List, ErrorIconStyled, FinishIcon } from './styles';

const StatusIcon: FC<{ status: string }> = ({ status }) => (
  <Fade in={!!status}>
    {status === 'finished' ? (
      <FinishIcon />
    ) : (
      <Tooltip placement='top' title={status}>
        <ErrorIconStyled />
      </Tooltip>
    )}
  </Fade>
);

export const AppPromotions: FC<IAppPromotionsProps> = ({ promotions, demotedPromotions }) => {
  const { app, events, experiments, flows, config, segment } = promotions;
  const { data } = useAppQuery(app);

  return (
    <div>
      <Typography variant='body1'>App {data?.name || app.serialize()}</Typography>
      <List>
        {events.map((event) => (
          <li key={event.id.id}>
            {event.id.serialize()} v.{event.version}
            <StatusIcon status={demotedPromotions[event.id.serialize()]} />
          </li>
        ))}
        {experiments.map((experiment) => (
          <li key={experiment.id.id}>
            {experiment.id.serialize()} v.{experiment.version}
            <StatusIcon status={demotedPromotions[experiment.id.serialize()]} />
          </li>
        ))}
        {flows.map((flow) => (
          <li key={flow.id.id}>
            {flow.id.serialize()} v.{flow.version}
            <StatusIcon status={demotedPromotions[flow.id.serialize()]} />
          </li>
        ))}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {config && (
          <li>
            AppConfig v.{config}
            <StatusIcon status={demotedPromotions[`${app.serialize()}#config`]} />
          </li>
        )}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {segment && (
          <li>
            Segmentation v.{segment}
            <StatusIcon status={demotedPromotions[`${app.serialize()}#segment`]} />
          </li>
        )}
      </List>
    </div>
  );
};
