import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { green } from '@mui/material/colors';

export const useSpaceComponentStyles = makeStyles((theme: Theme) => ({
  field: {
    marginBottom: theme.spacing(3),
  },
  actionButton: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actionWrapper: {
    position: 'relative',
  },
}));

export const useSpacesListStyles = makeStyles({
  actionsWrapper: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 10,
  },
});
