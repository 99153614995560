import { FC, useState } from 'react';

import { Space } from '@playq/octopus2-apps';

import { SpacesList } from './SpacesList';
import { SpaceComponent } from './SpaceComponent';
import { ISpaceSelectProps } from './types';

export const SpaceSelect: FC<ISpaceSelectProps> = (props) => {
  const { initialQuery, activeSpaceID, onSelect, onClose } = props;
  const [state, setState] = useState<{ space: Space; spacesQueryKey?: unknown[] }>();

  const handleCreate = () => {
    setState({ space: new Space() });
  };

  const handleEdit = (s: Space, spacesQueryKey: unknown[]) => {
    setState({ space: s, spacesQueryKey });
  };

  const handleSpaceComplete = () => {
    setState(undefined);
  };
  const handleUpdateSpace = (s: Space) =>
    setState((p) => ({
      ...p,
      space: s,
    }));

  return (
    <>
      {!state && (
        <SpacesList
          initialQuery={initialQuery}
          activeSpaceID={activeSpaceID}
          onCreate={handleCreate}
          onEdit={handleEdit}
          onSelect={onSelect}
          onClose={onClose}
        />
      )}
      {state && (
        <SpaceComponent
          space={state.space}
          spacesQueryKey={state.spacesQueryKey}
          onUpdateSpace={handleUpdateSpace}
          onClose={handleSpaceComplete}
          onCloseList={onClose}
        />
      )}
    </>
  );
};
