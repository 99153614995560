import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEventCallback } from '@mui/material';

import { Space } from '@playq/octopus2-apps';

import { activeSpaceToolkit } from '/store';

import { SpaceSelect } from './SpaceSelect';
import { IGlobalSpacesProps } from './types';

export const GlobalSpaceSelect: FC<IGlobalSpacesProps> = ({ onClose }) => {
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const dispatch = useDispatch();

  const putActiveSpace = useEventCallback((space?: Space) => {
    dispatch(activeSpaceToolkit.actions.put(space?.serialize()));
    onClose();
  });

  return <SpaceSelect activeSpaceID={activeSpace?.id} onClose={onClose} onSelect={putActiveSpace} />;
};
