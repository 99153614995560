import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EphemeralUserAt, EphemeralUserDisconnected } from '@playq/octopus-notifications';

import { history } from '/Router/history';
import { useIdle } from '/hooks/useIdle';
import { services2 } from '/api/services2';
import { appToolkit, authToolkit, currentModuleToolkit } from '/store';
import { buzzerAppNotifications$ } from '/common/buzzer/buzzerAppNotifications';
import { Class, CouponsClass } from '/common/models/enums';

export const OnlineUsersService: FC = () => {
  const dispatch = useDispatch();
  const app = useSelector(appToolkit.selectors.app);
  const user = useSelector(authToolkit.selectors.user);
  const { moduleClass, entityId, disableUserAtNotifications, onlineUsers } = useSelector(
    currentModuleToolkit.selectors.currentModule
  );
  const isIdle = useIdle();

  const sendEphemeralUserAt = useCallback(
    (response?: boolean) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (app && user && moduleClass && !disableUserAtNotifications) {
        const currentModule =
          moduleClass === CouponsClass.MassCouponsModule || moduleClass === CouponsClass.CouponTemplateModule
            ? Class.EconomyCoupons
            : moduleClass;
        const now = new Date();
        const ephemeralUserAt = new EphemeralUserAt();

        ephemeralUserAt.appID = app?.id.id;
        ephemeralUserAt.appName = app?.name;
        ephemeralUserAt.userID = user?.id;
        ephemeralUserAt.userName = user?.name;
        ephemeralUserAt.module_ = currentModule;
        ephemeralUserAt.at = now;
        ephemeralUserAt.status = response ? 'response' : undefined;
        ephemeralUserAt.atAsString = now.toISOString();
        ephemeralUserAt.entity = entityId;
        ephemeralUserAt.away = isIdle;

        services2.gatewayEphemeral.send(ephemeralUserAt);
      }
    },
    [app, user, moduleClass, entityId, disableUserAtNotifications, isIdle]
  );

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(currentModuleToolkit.actions.setModule(undefined));
      const now = new Date();
      const ephemeralUserDisconnected = new EphemeralUserDisconnected();

      ephemeralUserDisconnected.userID = user?.id;
      ephemeralUserDisconnected.userName = user?.name;
      ephemeralUserDisconnected.appID = app?.id.id;
      ephemeralUserDisconnected.appName = app?.name;
      ephemeralUserDisconnected.at = now;
      ephemeralUserDisconnected.status = history.location.pathname;
      ephemeralUserDisconnected.atAsString = now.toISOString();

      services2.gatewayEphemeral.send(ephemeralUserDisconnected);
    });
    return () => {
      unlisten();
    };
  }, [app, user, dispatch]);

  useEffect(() => {
    sendEphemeralUserAt();
  }, [sendEphemeralUserAt]);

  useEffect(() => {
    const sub = buzzerAppNotifications$.subscribe((notification) => {
      if (notification instanceof EphemeralUserAt) {
        const isNewModuleUser =
          notification.userID &&
          onlineUsers[notification.userID.serialize()] === undefined &&
          notification.module_ === moduleClass &&
          notification.entity === entityId &&
          notification.status !== 'response';
        if (isNewModuleUser) {
          sendEphemeralUserAt(true);
        }
        dispatch(currentModuleToolkit.actions.processModuleUser(notification.serialize()));
      } else if (notification instanceof EphemeralUserDisconnected) {
        dispatch(currentModuleToolkit.actions.deleteModuleUser(notification.serialize()));
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [dispatch, onlineUsers, moduleClass, entityId, sendEphemeralUserAt]);

  return null;
};
