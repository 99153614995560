export const LIVE_OPS_EVENTS = 'events';
export const LIVE_OPS_FLOWS = 'flows';

export const ECONOMY_INVENTORY = 'inventory';
export const ECONOMY_PACKAGES = 'packages';
export const ECONOMY_COUPONS_MASS = 'mass';
export const ECONOMY_COUPONS_TARGETED = 'targeted';

export const SOCIAL_LEADERBOARDS = 'leaderboards';
export const SOCIAL_MATCHES = 'matches';
export const SOCIAL_TEAMS = 'teams';

export const ANALYTICS_EXPERIMENTS = 'experiments';
export const ANALYTICS_BLUEPRINTS = 'blueprints';

export const ML_SYNAPSE = 'problems';

export const UA_COPY = 'copy';

export const SERVICES_CLOUD_FUNCTIONS = 'cloud-functions';
export const SERVICES_SCRIPTS = 'scripts';

export const FILES = 'files';

export const CORP_USERS = 'users';
export const CORP_GROUPS = 'groups';

export const CORP_INTEGRATIONS = 'integrations';
export const CORP_WEB_EVENTS = 'webevents';
export const CORP_CREDENTIALS = 'credentials';

export const SUPPORT_TEAMS_LOOKUP = 'teamslookup';

export const CONFIGURATION = 'configuration';
